import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  addDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
} from "firebase/firestore/lite";
import {
  Button, Container, FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import {
  BEGIN_TEST,
  FINISH,
  LISTENING_TEST,
  READING_TEST,
  REGION_DOMINIC_REPUBLICAN,
  REGION_GENERAL,
  REGION_INDIA,
  REGION_PHILLIPINES,
  VOCAB_TEST,
  APT_TEST,
  COMPREHENSIVE_TEST,
  EMOTIONAL_INTELLIGENCE_TEST,
  CUSTOMER_SERVICE_TEST,
  SALES_TEST
} from "./helper";
import AudioRecorder1 from "./AudioRecorder1";
import Banner from "./Banner";
import ListeningAssessment from "./ListeningAssessment";
import GrammerAssessment from "./GrammerAssessment";
import MySpinner from "./MySpinner";
import SoftBox from "./SoftBox";
import SoftButton from "./SoftButton";
import SoftTypography from "./SoftTypography";
import TagManager from "react-gtm-module";

const StyledButton = styled(Button)`
  flex-grow: 1;
  // position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default function Assessment() {

  const [testName, setTestName] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [user, setUser] = useState();
  const [assessment, setAssessment] = useState();

  const [steps1, setSteps1] = useState([BEGIN_TEST, FINISH])
  const [region, setRegion] = useState();
  const [passage1, setPassage1] = useState("");

  const [currentStep, setCurrentStep] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [aptitudeQuestions, setAptitudeQuestions] = useState([]);
  const [comprehensiveQuestions, setComprehensiveQuestions] = useState([]);
  const [emotionalIntelligenceQuestions, setEmotionalIntelligenceQuestions] = useState([]);
  const [customerServiceQuestions, setCustomerServiceQuestions] = useState([]);
  const [salesQuestions, setSalesQuestions] = useState([]);
  
  const [regions, setRegions] = useState([]);
  const [localStorageRegion, setLocalStorageRegion] = useState([]);

  const [isLoading, setIsLoading] = useState();
  const [audioFiles, setAudioFiles] = useState([]);
  const [isBeforeFinish, setIsBeforeFinish] = useState([])

  async function loadAudio(audioName, jsonName, fileName) {
    const audio = await import(`../../public/listening/${audioName}`);
    const json = await import(`../../public/listening/${jsonName}`);
    const jsonData = json.default.filter((item) => item.question !== "");
    return { audio_file: audio.default, json_file: jsonData, file_name: fileName };
  }

  const loadAudioFile = async (list) => {

    const shuffledList = [...list];
    for (let i = shuffledList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledList[i], shuffledList[j]] = [shuffledList[j], shuffledList[i]];
    }
    // Now shuffledList is a shuffled version of list
    const list1 = shuffledList.slice(0, 1);

    const promises = list1.map((item) =>
      loadAudio(item + ".mp3", item + ".json", item)
    );
    const results = await Promise.all(promises);
    // Promise.all(promises).then((results) => {
    //   return results;
    // });
    return results
  }

  useEffect(() => {
    if (steps1[currentStep] == "Finish") {
      const updateAssessment = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_SERVER_URL + "/update_assessment_status", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ assessmentUid: assessment.uid, testName: testName }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          console.log("Assessment updated successfully");
        } catch (error) {
          console.error("Error updating assessment:", error);
        }
      };
      updateAssessment();
      setSelectedTest("");
      localStorage.removeItem("localTestName")
    }
  }, [currentStep]);


  useEffect(() => {

    if (region && selectedTest) {
      const selectedTestDetails = region['tests'][selectedTest];
      const topics = selectedTestDetails.sections.map((section) => section.topic);
      const testNameData = region['tests'][selectedTest]?.Name
      setTestName(testNameData)
      localStorage.setItem("localTestName",testNameData)
      // Update the steps1 array
      setSteps1([BEGIN_TEST, ...topics, FINISH]);
      setIsBeforeFinish([BEGIN_TEST, ...topics, FINISH])
    }
  }, [selectedTest]);

  useEffect(() => {
    const list = ["room", "repairs", "flowershop", "expense"];
    const regionsData = [];
    const localStorageRegion = sessionStorage.getItem('region');
    setLocalStorageRegion(localStorageRegion)
    const regionRef = collection(db, "regions");
    getDocs(regionRef)
      .then(async (querySnapshot) => {
        querySnapshot.forEach((docSnapshot) => {
          regionsData.push(docSnapshot.data());
        });
        setRegions(regionsData);
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      })

    loadAudioFile(list)

  }, []);

  let { q } = useParams();
  useEffect(() => {
    setIsLoading(true);
    const userRef = doc(db, "users", q);

    getDoc(userRef)
      .then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          let isCreateNewAssessment = false;
          const q1 = query(
            collection(db, "assessments"),
            where("userId", "==", docSnapshot.id),
            orderBy("createdAt", "desc"),
            limit(1)
          );
          let region_obj = null

          const querySnapshot = await getDocs(q1);
          if (querySnapshot.empty) {
            isCreateNewAssessment = true;
          } else {
            const assessement1 = querySnapshot.docs[0].data();
            
            if(assessement1 && assessement1.region) {
              const q2 = query(
                collection(db, "regions"),
                where("region", "==", assessement1.region)
              );
              const regionSnapshot = await getDocs(q2);
              region_obj = regionSnapshot.docs[0].data();
 
            }

            if (assessement1.currentStep === "Finish") {
              isCreateNewAssessment = false;
              const stepIndex = steps1.indexOf(assessement1.currentStep);
              setCurrentStep(stepIndex);

            } else {
              assessement1.uid = querySnapshot.docs[0].id;
              setAssessment(assessement1);
              setQuestions(assessement1.vocabtext)
              setAptitudeQuestions(assessement1.aptitude);
              setComprehensiveQuestions(assessement1.comprehensive);
              setEmotionalIntelligenceQuestions(assessement1.emotional_intelligence);
              setCustomerServiceQuestions(assessement1.customer_service);
              setSalesQuestions(assessement1.sales);
              setPassage1(assessement1.passage)
              if (assessement1.audio_file) {
                const audioFiles1 = await loadAudioFile([assessement1.audio_file])
                setAudioFiles(audioFiles1)
              }

              setRegion(region_obj)
              const steps2 = [BEGIN_TEST];
              const selectedTestData = region_obj?.tests[assessement1.test_name || userData.test_name];
              if (selectedTestData?.sections) {
                setTestName(selectedTestData.Name)
                setSelectedTest(assessement1.test_name || userData.test_name)
                // Map sections to extract topics
                const topics = selectedTestData.sections.map(
                  (section) => section.topic
                );

                // Update steps1 with BEGIN_TEST, topics, and FINISH
                setSteps1([BEGIN_TEST, ...topics, FINISH]);
                setIsBeforeFinish([BEGIN_TEST, ...topics, FINISH])
                steps2.push(...topics)
                steps2.push(FINISH)

              }

              const stepIndex = steps2.indexOf(
                assessement1.currentStep || BEGIN_TEST
              );

              setCurrentStep(stepIndex);
            }
          }
          // for external user
          if (isCreateNewAssessment) {  
            await createNewAssessment(userData)
          }
          setUser(userData);

          TagManager.dataLayer({
            dataLayer: {
              event: "component_loaded",
              component: "Assessment",
              userData: userData
            },
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [q]);

  const handleInitialSubmit = () => {
    sessionStorage.removeItem('region')
    sessionStorage.removeItem('isRegion')
    try {
      setIsLoading(true)
      setRegion(region)

      const apiUrl = `${process.env.REACT_APP_SERVER_URL}/get_questions?region=${region.region}&test_name=${selectedTest}&assessment=${assessment.uid}`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(apiUrl, options)
        .then(async (response) => response.json())
        .then(async (data) => {
          const questionsData = data.Vocabulary
          const aptitudeQuestions = data.aptitude;
          const comprehensiveQuestions = data.comprehensive;
          const emotionalIntelligenceQuestions = data.emotional_intelligence;
          const customerServiceQuestions = data.customer_service;
          const salesQuestions = data.sales;
          setQuestions(questionsData);
          setAptitudeQuestions(aptitudeQuestions);
          setComprehensiveQuestions(comprehensiveQuestions);
          setEmotionalIntelligenceQuestions(emotionalIntelligenceQuestions);
          setCustomerServiceQuestions(customerServiceQuestions);
          setSalesQuestions(salesQuestions); 
          setPassage1(data.reading.passage1);

          const audioFiles1 = await loadAudioFile([data.listening.field])
          setAudioFiles(audioFiles1)
          setIsLoading(false)
          handleNextStep()
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });

    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }

  }


  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  useEffect(() => { 
    setTimeout(() => { 
      window.scrollTo(0, 0);  
    }, 300);
  }, [currentStep]);

  const createNewAssessment = async (userData) => {
    if(userData.region){
    const q2 = query(
      collection(db, "regions"),
      where("region", "==", userData.region)
    );
    const regionSnapshot = await getDocs(q2);
    const region = regionSnapshot.docs[0].data();
    setRegion(region)
  }
    const assessment1 = {
      userId: userData.uid,
      createdAt: serverTimestamp(),
      currentStep: BEGIN_TEST,
      ...(userData.region && { region: userData.region }),
    };
    const assessRef = collection(db, "assessments");

    addDoc(assessRef, assessment1)
      .then((docRef) => {
        assessment1.uid = docRef.id;
        setAssessment(assessment1);
        setCurrentStep(0);
        updateDoc(doc(collection(db, "users"), userData.uid), {
          currentStep: BEGIN_TEST,
          resultStatus: "Initial",
        }).then((userDocRef) => {
          console.log("Updated the user ", userDocRef);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    // window.scrollTo(0, 0);
  };

  // const steps = [BEGIN_TEST, VOCAB_TEST, READING_TEST, LISTENING_TEST, FINISH];

  const getButtons = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {currentStep > 0 ? (
          <StyledButton onClick={handlePrevStep}>Previous</StyledButton>
        ) : (
          <StyledButton style={{ visibility: "hidden" }}>Previous</StyledButton>
        )}
        {currentStep < 4 ? (

          <StyledButton onClick={() => handleNextStep()}>Next</StyledButton>
        ) : (
          <StyledButton style={{ visibility: "hidden" }}>Next</StyledButton>
        )}
      </div>
    );
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const renderRegionButton = (region1) => {
    const assessmentName = regions.find((r) => r.region === region1)?.assessment_name;
    return (
      <SoftBox mt={4} mb={1}>
        <SoftButton
          variant="contained"
          color="gt_primary"
          onClick={() => handleInitialSubmit()}
          fullWidth
        >
          {testName}
        </SoftButton>
      </SoftBox>
    );
  };

  // Function to render the tests dropdown for a selected region
  const renderTestsDropdown = (region) => (
    <FormControl
      size="small"
      sx={{
        marginBottom: "16px",
        width: "250px",
        marginTop:"30px"
      }}
    >
      <InputLabel sx={{fontSize: "1rem"}}>Select the Tests</InputLabel>
      <Select
        value={selectedTest}
        onChange={(event) => {
          console.log('up')
          setSelectedTest(event.target.value)
        }}
        sx={{
          "& .MuiSelect-select": {
            minWidth: "100%",
          },
        }}
      >
        <MenuItem value="">Select Test</MenuItem>
        {Object.keys(region.tests).map((testKey) => (
          <MenuItem key={testKey} value={testKey}>
            {region.tests[testKey].Name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  // Function to render the regions dropdown
  const renderRegionsDropdown = () => (
    <FormControl
      size="small"
      sx={{
        width: "100%",
        width: "250px",
        marginTop:"30px"
      }}
    >
      <InputLabel sx={{fontSize: "1rem"}}>Select Region</InputLabel>
      <Select
        value={region ? region.region : ""}
        onChange={(event) => {
          const selectedRegion = regions.find((r) => r.region === event.target.value);
          setRegion(selectedRegion);
          setSelectedTest(""); // Reset test selection on region change
        }}
        sx={{
          "& .MuiSelect-select": {
            minWidth: "100%",
          },
        }}
      >
        <MenuItem value="">Select Region</MenuItem>
        {regions.map((regionData) => (
          <MenuItem key={regionData.region} value={regionData.region}>
            {regionData.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderContent = () => {
    if (selectedTest) {
      return renderRegionButton(region?.region || localStorageRegion);
    } else if (localStorageRegion) {
      return renderRegionButton(localStorageRegion);
    } else if (region) {
      if(Object.keys(region.tests).length > 1){
        return renderTestsDropdown(region);
      }else{
        const keyName = Object.keys(region.tests)[0];
        setSelectedTest(keyName);
      }
    } else {
      return renderRegionsDropdown();
    }
  };
  
  const beginTestComponent = () => {
    return (
      <>
        {user && (
          <Container>
            <SoftBox mt={1}>
              <SoftTypography variant="h5" fontWeight="bold" color="dark">
                Greetings, {`${user.firstName} ${user.lastName}`}
              </SoftTypography>
            </SoftBox>

            <SoftBox mt={1}>
              <SoftTypography variant="h6" fontWeight="medium" color="dark">
                We are excited to welcome you to our Candidate Assessment platform, where you will undergo a comprehensive evaluation of your skills and abilities. 
              </SoftTypography>
            </SoftBox>

            <p className="text-center"></p>
            <SoftBox mt={1}>
              <SoftTypography variant="h6" fontWeight="medium" color="dark">
              The assessment consists of 7 sections, Containing about 45 questions. It begins with a test on English vocabulary and grammar using multiple-choice questions, followed by sections on Aptitude, Comprehension, Customer Service, and Sales Skills.
              </SoftTypography>
            </SoftBox>

            <p className="text-center"></p>
            <SoftBox mt={1}>
              <SoftTypography variant="h6" fontWeight="medium" color="dark">
              Additionally, the Reading Test requires activating your browser’s audio and reading aloud a passage presented to you, and the final test assesses your Listening Comprehension by presenting audio clips followed by multiple-choice questions.
              </SoftTypography>
            </SoftBox>
            <p className="text-center"></p>
            <SoftBox mt={1}>
              <SoftTypography variant="h6" fontWeight="medium" color="dark">
                To ensure a smooth assessment experience, please ensure a quiet environment and a stable internet connection. Lastly, kindly review your account information to confirm its accuracy before starting.
                <br/><br/>
                {user.phoneNumber && (<>
                  Phone Number - {user.phoneNumber} <br />
                </>)}
                {user.email && (<>
                  Email - {user.email} <br />
                </>)}
              </SoftTypography>
            </SoftBox>


            <> {renderContent()}</>

            {/* <p className="text-center mt-3">
                  Please note that each test has a time limit, and you will need
                  to complete each test within the allotted time.
                </p> */}
          </Container>
        )}
      </>
    )
  }
  const renderStep = () => {

    const currentStepIndex = isBeforeFinish.indexOf(steps1[currentStep]);
    let nextValue = ""
    if (currentStepIndex !== -1 && currentStepIndex < isBeforeFinish.length - 1) {
      nextValue = isBeforeFinish[currentStepIndex + 1];
    }
    
    switch (steps1[currentStep]) {
      case BEGIN_TEST:
        return beginTestComponent()
      case VOCAB_TEST:
      case APT_TEST:
      case COMPREHENSIVE_TEST:
      case EMOTIONAL_INTELLIGENCE_TEST:
      case CUSTOMER_SERVICE_TEST:
      case SALES_TEST:
        {
          const all_questions =
            steps1[currentStep] === VOCAB_TEST ? questions :
              steps1[currentStep] === APT_TEST ? aptitudeQuestions :
                steps1[currentStep] === COMPREHENSIVE_TEST ? comprehensiveQuestions :
                  steps1[currentStep] === EMOTIONAL_INTELLIGENCE_TEST ? emotionalIntelligenceQuestions :
                    steps1[currentStep] === CUSTOMER_SERVICE_TEST ? customerServiceQuestions :
                        steps1[currentStep] === SALES_TEST ? salesQuestions :
                      []; // Fallback to an empty array if none match

          // Render the GrammerAssessment component with the correct props
          return (
            <GrammerAssessment
              user={user}
              category={steps1[currentStep]}
              assessment={assessment}
              handleNextStep={handleNextStep}
              region={region}
              testName={testName?testName:localStorage.getItem("localTestName")}
              questions={all_questions}
              nextSectionName={nextValue}
            />
          );
        }

      case READING_TEST:
        return (
          <AudioRecorder1
            user={user}
            assessment={assessment}
            handleNextStep={handleNextStep}
            testName={testName?testName:localStorage.getItem("localTestName")}
            region={region}
            passage={passage1}
            nextSectionName={nextValue}
          />
        );
      case LISTENING_TEST:
        return (
          <ListeningAssessment
            audioFiles={audioFiles}
            user={user}
            assessment={assessment}
            testName={testName?testName:localStorage.getItem("localTestName")}
            region={region}
            handleNextStep={handleNextStep}
            nextSectionName={nextValue}
          />
        );
      case FINISH:
        return (
          <Container style={{ marginTop: "12px" }}>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="body1" fontWeight="medium" color="dark">
                Thanks for taking the assessment, will get in touch soon.
              </SoftTypography>
            </SoftBox>

            <p></p>
            <Link to={`/review/${user.uid}`} style={{ textDecoration: "none" }}>
              <SoftButton variant="text" color="gt_primary">Review Your Assessment</SoftButton>
            </Link>
            <p></p>
            <SoftButton variant="text" color="gt_primary"
              onClick={() => createNewAssessment(user)}>
              Take new assessment
            </SoftButton>
          </Container>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="spinner">
        <MySpinner />
      </div>
    );
  }

  return (
    <div className="container">
      <Banner showBackButton={false} />
      <div className="row justify-content-center mt-5">
        <div className="col-md-8 col-sm-12">
          {user && (
            <div>{/* <h1>{`${user.firstName} ${user.lastName}`}</h1> */}</div>
          )}
          {renderStep()}
        </div>
      </div>

      {/* {getButtons()} */}
    </div>
  );
}