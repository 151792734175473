import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  where,
  updateDoc,
  query,
  serverTimestamp
} from "firebase/firestore/lite";
import {
  Button,
  Typography,
  Container,
  Grid,
  Card,
  FormControlLabel,
  Radio,
} from "@mui/material";
import MySpinner from "./MySpinner";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftButton from "./SoftButton";
import TagManager from "react-gtm-module";
import {
  BEGIN_TEST,
  FINISH,
  LISTENING_TEST,
  READING_TEST,
  VOCAB_TEST,
  APT_TEST,
  COMPREHENSIVE_TEST,
  EMOTIONAL_INTELLIGENCE_TEST,
  CUSTOMER_SERVICE_TEST,
  SALES_TEST
} from "./helper";

export default function GrammerAssessment({ assessment, handleNextStep, region, questions, category, testName, nextSectionName }) {
  const [loadingVocab, setLoadingVocab] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [vocabAnswers, setVocabAnswers] = useState([]);
  const [submitScores, setSubmitScores] = useState(false);

  useEffect(() => {
    if(questions) {
      setSelectedAnswers(Array(questions.length).fill(""));
    }
    
  }, [questions]);

  const handleAnswer = (e, value, index) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = value;
    setSelectedAnswers(newAnswers);
  };
  
  const handleNextStep1 = useCallback(() => {
    handleNextStep();
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "component_loaded",
        component: "GrammerAssessment",
        questions: questions
      },
    });
  }, []);


  useEffect(() => {
    if (submitScores) {
      const formData = new FormData();
      formData.append("assessment_uid", assessment.uid);
      vocabAnswers.forEach((answer, index) => {
        formData.append(`answers[${index}]`, selectedAnswers);
      });
      const myObj = {
        assessment_uid: assessment.uid,
        answers: selectedAnswers
      }
      fetch(
        process.env.REACT_APP_SERVER_URL + "/update_grammar_scores",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // body: formData.toString(),
          body: JSON.stringify(myObj),
          // agent: new https.Agent({
          //   rejectUnauthorized: false,
          //   requestCert: false,
          //   agent: false,
          // }),
        }
      );

      // updateDoc(doc(collection(db, "assessments"), assessment.uid), {
      //   vocabScore: score,
      //   vocabtext: vocabAnswers,
      //   currentStep: READING_TEST,
      //   grammer_time: serverTimestamp(),
      // })
      //   .then(() => {
      //     console.log("Updated audio URL successfully");
      //   })
      //   .catch((error) => {
      //     console.error("Error updating audio URL:", error);
      //   });
      //   updateDoc(doc(collection(db, "users"), assessment.userId), {
      //     vocabScore: score,
      //   })
      handleNextStep1();
    }
  }, [handleNextStep1, submitScores, vocabAnswers]);


  const submit = () => {
    const newVocabAnswers = questions.map((question, index) => {
      const {
        question: questionText,
        correct_answer,
        difficulty,
        category,
      } = question;
      const answer = selectedAnswers[index];
      return {
        question: questionText,
        answer,
        difficulty,
        category,
        correct_answer,
      };
    });
    const newVocabAnswers1 = questions.map((question, index) => {
      const {
        question: questionText,
        correct_answer,
        difficulty,
        category,
      } = question;
      const answer = selectedAnswers[index];
      return answer
      // question: questionText,

      // difficulty,
      // category,
      // correct_answer,

    });


    setVocabAnswers(newVocabAnswers1);
    setSubmitScores(true);
  };

  const section_header = 
            category === VOCAB_TEST ? "Vocabulary and Grammar" :
            category === APT_TEST ? "Aptitude" :
            category === COMPREHENSIVE_TEST ? "Comprehension" :
            category === EMOTIONAL_INTELLIGENCE_TEST ? "Emotional Intelligence and Call Center Knowledge" :
            category === CUSTOMER_SERVICE_TEST ? "Customer Service" :
            category === SALES_TEST ? "Sales" :
            [];

  const renderGrammer = () => {
    if (loadingVocab || !questions) {
      return (
        <div className="spinner">
          <MySpinner />
        </div>
      );
    } else {
      return (
        <>
          <SoftBox mt={1} mb={1}>
            <SoftTypography variant="body1" fontWeight="bold" color="dark">
              <div style={{display:"flex"}}>
                <p style={{flex:"0.5"}}>{section_header}</p>
                <p style={{flex:"0.5", textAlign:"end"}}>{testName}</p>
              </div>
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={1} mb={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="dark">
              Choose the word that best completes the sentence
            </SoftTypography>
          </SoftBox>
          <SoftBox pt={1}>
            {questions.map((question, index) => (
              <Card
                mb={2}
                variant="outlined"
                style={{ marginBottom: "1rem", paddingBottom: "1rem" }}
              >
                <SoftBox lineHeight={0}>
                  <SoftBox mb={1} pl={1} bgColor="grey-100">
                    <SoftTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                      style={{ paddingBottom: "8px" }}
                    >
                      {question.question}
                    </SoftTypography>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        height: '15px',
                        fontWeight: 200,
                        fontSize: "0.8rem",
                        marginTop: "2px",
                        marginBottom: "4px",
                        bgColor: "grey-100",
                      }}
                    >
                      Q {index + 1} of {questions.length}
                    </div>
                  </SoftBox>

                  <SoftBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    mt={0.25}
                    pt={0.5}
                  >
                    {question.options.map((choice, choiceIndex) => (
                      <SoftBox
                        component="li"
                        display="flex"
                        alignItems="center"
                        borderRadius="lg"
                        p={0.25}
                        px={2.5}
                        mb={0}
                      >
                        <FormControlLabel
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          control={
                            <Radio
                              id={`answer-${index}-${choiceIndex}`}
                              name={`answer-${index}`}
                              value={choice}
                              checked={selectedAnswers[index] === choice}
                              onChange={(e) => handleAnswer(e, choice, index)}
                              sx={{
                                "&.MuiRadio-root": {
                                  height: "18px",
                                  width: "18px",
                                },
                              }}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                lineHeight: "1rem",
                                paddingLeft: "0",
                              }}
                            >
                              <SoftTypography
                                variant="body2"
                                fontWeight="medium"
                                color="dark"
                                ml={0.5}
                              >
                                {choice}
                              </SoftTypography>
                            </div>
                          }
                        />
                      </SoftBox>
                    ))}
                  </SoftBox>
                </SoftBox>
              </Card>
            ))}
          </SoftBox>

          <SoftBox mt={1} mb={2}>
            <SoftBox mt={2} mb={1}>
              <SoftTypography variant="body2" fontWeight="medium" color="dark">
                Please answer all questions above to continue
              </SoftTypography>
            </SoftBox>

          </SoftBox>
          <SoftBox mt={1} mb={2}>
            <SoftBox mt={2} mb={1}>
              <SoftButton
                variant="contained"
                color="gt_primary"
                onClick={submit}
                fullWidth
                disabled={
                  selectedAnswers.length !== questions.length ||
                  selectedAnswers.includes("")
                }
                sx={{
                  "&.Mui-disabled": {
                    opacity: "0.6",
                  },
                }}
              >
                {nextSectionName === "Finish" ? "Submit" : "Next"}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </>
      );
    }
  };

  return (
    <Container>
      {renderGrammer()}
    </Container>
  );
}
