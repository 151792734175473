/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import React from "react";
import PropTypes from "prop-types";

// @mui material components
import { AccountCircle, Mail } from '@material-ui/icons';
import QuizIcon from '@mui/icons-material/Quiz';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import {
  Card
} from "@mui/material";

function Bill({ showCodeGenerated, name, email, phone, noGutter, status, completion, row, statusColor }) {

  let completionDate = null
  let score = null
  if (completion) {
    completionDate = new Date(completion.seconds * 1000);

    // const readingSimilarity = parseFloat(row.reading_similarity);
    // const vocabScore = parseFloat(row.vocabScore);
    // const listeningScore = parseFloat(row.listeningScore);
    // const score1 = (readingSimilarity + vocabScore + listeningScore);
    score = row.calculated_percentage;
  }  

  return (

    <Card>
      <SoftBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        borderRadius="lg"
        px={2}
        mb={1}
        mt={1}
      >
        <SoftBox width="100%" display="flex" flexDirection="column">
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={0.5}
          >
            <SoftTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {name}
            </SoftTypography>

          <SoftBox
            display="flex"
            alignItems="center"
            mt={{ xs: 1, sm: 0 }}
          >
            {showCodeGenerated ? (
            <SoftTypography
            variant="h7"
            fontWeight="medium"
            style={{ color: statusColor }} // Apply color here
            >
            {status}
          </SoftTypography>
            ) : (
            <SoftTypography
            variant="h7_bold"
            fontWeight="600"
            style={{ color: statusColor }} // Apply color here
            >
            {status}
          </SoftTypography>
          )}
        </SoftBox>
          </SoftBox>
          <SoftBox mb={0.25} lineHeight={0}>
            <SoftTypography variant="h7" color="text">
              Email Address:&nbsp;&nbsp;
              <SoftTypography variant="h7" fontWeight="600" style={{ fontWeight: '500' }}>
                {email}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftTypography variant="h7" color="text">
            Phone:&nbsp;&nbsp;
            <SoftTypography variant="h7" fontWeight="medium" style={{ fontWeight: '500' }}>
              {phone}
            </SoftTypography>
          </SoftTypography>
          <SoftBox style={{ display: 'flex', justifyContent: 'space-between' }}>
            {completionDate && (
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                <QuizIcon />
                <SoftTypography variant="h7" fontWeight="medium" style={{ fontWeight: '500', marginLeft: '8px' }}>
                  {completionDate.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "2-digit",
                  })}
                </SoftTypography>
              </div>
            )}
            {score && !isNaN(score) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <QuestionAnswerIcon />
                <SoftTypography variant="h7" fontWeight="medium" style={{ fontWeight: '500', marginLeft: '8px' }}>
                  {score} %
                </SoftTypography>
              </div>
            )}
          </SoftBox>


        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
