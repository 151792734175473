import React from "react";
import { useAuth } from './auth-context/auth.context';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

// const pattern = /^\/region\/:[a-zA-Z0-9]+$/;
const pattern = /^\/region\/[^/]+$/;
export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { user } = useAuth();
  const parts = location.pathname.split("/");

  // sessionStorage.setItem("isRegion", true)
  // sessionStorage.setItem("region", parts[2])

    return (<>
      {(!user || !user.token || user.token === "") ? (
        <SweetAlert
          title="You must be signed in!"
          onCancel={() => navigate("/login")}
          onConfirm={() => navigate("/login")}
          confirmBtnCssClass={"px-5"}
        />
      ) : (
        <Outlet />        
      )}
  </>);
};
